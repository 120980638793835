import React from 'react';
import speakingproduct from './speaking-product-logo.svg'
/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
// export default function Logo({ fill }) {

export default function Logo() {
  return (
    <img
        widht="250"
        height="30"
        fill={'primary'}
        src={speakingproduct}
        alt="Speaking Product Logo"
    />
    
    // <svg viewBox="0 0 106 28" height="30px">
    //   <path d="M62.9 12h2.8v10...." />
    //   <path fill={fill} d="M25 14h-..." />
    //   <path d="M14 0C6.3..." fill="#639" />
    // </svg>
  );
}